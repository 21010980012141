import { useTheme } from "@emotion/react";
import useWindowDimensions from "../../assets/hook/WindowsDimensionsHook";
import ContactCV from "../molecules/Contact/ContactCV";
import ContactLinkedin from "../molecules/Contact/ContactLinkedin";
import ContactMail from "../molecules/Contact/ContactMail";

function ContactBlocks() {
    const theme = useTheme();

    return (
        
        <div style={{
            display: "flex",
            flexDirection: (useWindowDimensions().width > 900 ? "row" : "column"),
        }}>
            <div style={{
                display: 'flex',
                marginBottom: (useWindowDimensions().width > 900 ? "40px" : "20px"),
                justifyContent: (useWindowDimensions().width > 900 ? "left" : "center")
            }}>
                <ContactMail></ContactMail>
                <ContactLinkedin></ContactLinkedin>
                <ContactCV></ContactCV>
            </div>
            <p>
                <a 
                style={{color: theme.palette.mode === 'dark' ? theme.palette.white : theme.palette.secondary.main, }}
                href="http://rdv.gregory-mostacci.fr">Echangeons ensemble, prenez rendez-vous</a>
            </p>
        </div>



    );
}

export default ContactBlocks;